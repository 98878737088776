@import "@/assets/scss/var.scss";
.sw-radio-group {
    margin: $offset-base-sm 0 $offset-base 0;
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    /deep/ .el-radio{
        position: relative;
        width: calc(50% - 10px - 10px);
        height: auto;
        margin: $offset-base $offset-base 0 $offset-base;
        padding: $offset-base;
        min-height: 45px;
        .el-radio__label{
            padding-left: 0px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            height: 100%;
            justify-content: space-between;
        } 
        .el-radio__label .el-image {
            margin-right: $offset-h-sm;
        }
        .el-radio__input.is-checked + .el-radio__label::before {
            content: "";
            display: block;
            width: $offset-base;
            height: $offset-base-sm;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: $offset-base-sm / 2;
            bottom: $offset-base-sm;
            z-index: 1;
        }
        .el-radio__input.is-checked + .el-radio__label::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-bottom: $offset-v-lg solid $border-primary;
            border-left: 25px solid transparent;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        &.is-bordered .el-radio__inner {
            opacity: 0;
            outline: none;
            position: absolute;
            margin: 0;
            z-index: -1;
            height: 0px;
            width: 0px;
        }
        &.el-radio.is-bordered.is-disabled {
            cursor: not-allowed;
            border: 2px dashed;
            border-color: #ebeef5;
        }
    }
    &__info{
        &-img{
            width: 40px;
            height: 40px;
            margin-right: $offset-h-sm;
        }
        display: inline-flex;
        align-items: center;
        height: 100%;
        &__bottom{
            flex: 1;
            &-name{
                @extend .twoRows;
            }
            &-desc{
                color:$txt-color-grey;
                font-size: $fontsize-s;
                white-space: normal;
                word-break: break-all;
            }
        }
    }
}

@import "@/assets/scss/var.scss";
.goods {
    background: #fff;
    &-chapter {
        color: $color-primary;
    }
    .teacher {
        padding: 15px;
        background: #fff;
        &-pro {
            margin: $offset-v-md 0;
            display: flex;
            flex-wrap: wrap;

            background: $bg-color-grey;
            padding: $offset-base;
        }
        .pro-items {
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-flow: column;
            margin: 5px 0 5px 0;
            &-name {
                margin-top: 5px;
                margin-left: 10px;
            }
        }
        .pro-items+.pro-items {
            margin-right: 10px;
        }
        .title {
          font-weight: bold;
          font-size: 1.25em;
          margin: $offset-v 0;
        }
    }
    .checkPt-tips {
        /deep/ .el-alert__description {
            font-size: 14px;
            font-weight: bold;
            .is-pointer {
                cursor: pointer;
            }
        }
    }
    .van-count-down{
        color: #fff;
    }
    .btnGrp{
        height: 60px;
    }
    .btnGrp-container {
        height: 60px;
        .el-alert {
            margin-bottom: 10px;
        }
    }

    &-op {
        display: flex;
        align-items: center;
        color: $txt-color-grey;
        margin: 10px 0;
        .share-text {
            // color: $color-primary;
            // font-size: 14px;
        }
    }
    &-preInfo {
        color: $color-primary;
    }

    .share-sources {
        font-size: 14px;

        > b {
            color: $color-primary;
            font-weight: bold;
        }
    }
    // &-share {
    //     cursor: pointer;
    // }
    .goods-share {
        cursor: pointer;
        background: #1877f2;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        padding: 4px 10px;
        position: relative;
    }
    .goods-share:before {
        content: "";
        display: block;
        border-style: solid;
        border-width: 5px;
        border-color: transparent transparent #1877f2;
        position: absolute;
        bottom: 100%;
        left: 16px;
    }
    .goods-share:after {
        content: "";
        position: absolute;
        width: 70%;
        height: 100%;
        top: 0;
        left: -50%;
        overflow: hidden;
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 75%);
        transform: skewX(-45deg);
        animation: fbshare 2s infinite linear;
    }

    @keyframes fbshare {
        from {
            left: -200%;
        }
        to {
            left: 200%;
        }
    }
    .noImg {
        // height: 400px;
        padding-bottom: 100%;
        height: auto;
        position: relative;
        .el-image {
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
        }
        /deep/ .image-slot {
            font-size: 100px;
        }
    }
    /deep/ .hv-swiper {
        .my-swipe {
            height: auto;
            .el-carousel__container {
                height: auto !important;
                padding-bottom: 100%;
            }
            .el-carousel__indicators {
                position: static;
                left: 0;
                right: 0;
                transform: none;
                text-align: center;
            }
            .el-carousel__button {
                // opacity: .25;
                background-color: #000;
            }
        }
    }
    .btnGrp-container.is-active {
        // box-sizing: border-box;
        // position: fixed;
        // top: 0;
        // z-index: 3;
        // // background: #fff;
        // height: 61px;
        // // right: 0;
        // // left: 50%;
        // transform: translateX(-50%);
        // // margin-left: 15px;
        // // margin: auto;
        // // right: 0;
        // display: flex;
        // justify-content: flex-end;
        // flex-direction: row;
        // align-items: center;
        // width: 100%;        
        // max-width: $com-width;
        // padding-right: $com-offset;
    }

    &-content {
        display: flex;
        flex-wrap: wrap;
        // width: 1000px;
        // min-width: 960px;
        // margin: 0 auto;
        // font-size: 16px;
        // padding: 20px 0;
        &-left {
            // min-width: 400px;
            width: 40%;
            margin-right: 15px;
        }
        &-right {
            flex: 1;
            text-align: left;
            > div {
                margin-bottom: 20px;
            }
        }
        &-full {
            width: 100%;
        }
    }
    &-title {
        // margin: 20px 0;
        font-size: 22px;
        font-weight: bold;
        // margin-top: 0;
    }
    &-suits {
        .suits {
            margin-top: $offset-v-md;
            display: flex;
            flex-wrap: wrap;
            background: $bg-color-grey;
            padding: $offset-base;
            &-item {
                width: 65px;
                margin: $offset-base;
                text-align: center;
                &-img {
                    width: 65px;
                    height: 65px;
                }
                &-name {
                    width: 100%;
                    font-size: $fontsize-s;
                }
            }
            &-icon {
                display: flex;
                align-items: center;
                margin: $offset-base;
                height: 65px;
            }
            .is-pointer {
                cursor: pointer;
            }
        }
    }
    &-number {
        margin-right: 10px;
    }
    .coupon {
        .coupon-tag {
            margin-top: 10px;
            margin-bottom: 10px;
            border-bottom: 1px dotted #ddd;
            padding-bottom: 20px;
            .el-tag {
                cursor: pointer;
            }
            .el-tag + .el-tag {
                margin-left: 10px;
            }
        }
    }
    .alert {
        background: #fff;
        border-color: #fd8167;
        color: #fd8167;
        margin-bottom: 12px;
    }
    .total {
        background: $bg-color-grey;
        padding: 20px 24px;
        ul,
        li {
            margin: 0;
            padding: 0;
            list-style: 0;
        }
        > ul > li {
            font-size: 14px;
            margin: 0;
            margin-bottom: 6px;
            display: flex;
            justify-content: space-between;
            .num {
                margin-left: 10px;
            }
            .color {
                margin-left: 10px;
            }
            .gift {
                color: #ff0000;
            }
        }
        .totalPrice {
            color: $color-primary;
            font-size: 19px;
            margin-top: 10px;
            i {
                font-style: italic;
            }
        }
        .disPrice {
            margin-top: 10px;
            i {
                font-style: italic;
            }
            .red {
                font-size: 19px;
                color: #dd381c;
            }
        }
    }
    .goods-content-right {
        > .goods-sale-countdown {
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
        }
    }
    &-sale-countdown {
        padding: 10px 12px;
        color: #fff;
        // background: linear-gradient(-45deg, #1fdb93, #00c4a2);
        background: linear-gradient(-45deg, #ff6034, #ee0a24);
        span:first-child {
            margin-right: 6px;
        }
    }
    &-sale-countdown-title {
        font-weight: bold;
    }
    &-sale-price {
        padding: 10px 12px;
        background: $bg-color-grey;
        .price {
            margin-bottom: 6px;
            line-height: 35px;
            div:first-child {
                color: #999;
            }
            .o-price .label,
            .sell-price .label {
                display: inline-block;
                margin-right: 12px;
                font-size: 14px;
                min-width: 80px;
                text-align: justify;
                text-align-last: justify;
                color: #999;
            }
            .o-price em {
                position: relative;
                display: inline-block;
            }
            .o-price em:after,
            .o-price em:before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(15deg);
                border-top: 1px solid;
            }
            .o-price em:after {
                transform: translate(-50%, -50%) rotate(-15deg);
            }
            .sell-price {
                em {
                    text-decoration: line-through;
                }
                .num {
                    color: $color-primary;
                    font-size: 1.5em;
                }
                i,
                .disc {
                    color: $color-primary;
                    font-style: italic;
                }
            }
            .coupon {
                font-size: 14px;
                &-tags {
                    display: inline-block;
                    margin-right: 5px;
                    > span {
                        display: flex;
                        align-items: center;
                    }
                    .el-tag {
                        margin-right: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &-bottom {
        // background: $bg-color-grey;
        padding: 20px 0;
        /deep/ .el-tabs {
            .el-tabs__item {
                font-size: 16px;
                @media (min-width: $tablet) {
                    min-width: 120px;
                }
                text-align: center;
                line-height: 44px;
                height: 44px;
                padding-left: 20px;
                padding-right: 20px;
                &.is-active {
                    // border-bottom: 3px solid $color-shop;
                }
            }
            .el-tabs__active-bar {
                display: none;
            }
        }

        .el-tabs {  
            /deep/.el-tabs__header {
                position: sticky;
                margin: 0px;
                top: 0px;
                z-index: 2;
                // width: 1000px;
                // background: rgb(255, 255, 255);
                height: 61px;
                // border-bottom: 2px solid $color-primary;
                .el-tabs__nav-wrap {
                    height: 100%;
                    line-height: 61px;
                }
                .el-tabs__nav-wrap:after {
                    content: none;
                }
                // .el-tabs__active-bar {
                //     display: none;
                // }
                &.is-top::before {
                    content: "" !important;
                    display: block !important;
                    position: absolute;
                    top: 0; left: -9999px; right: -9999px;
                    bottom: -1px;
                    background: #fff;
                    border-bottom: 1px solid $border;
                }  
            }
        }
        .goods-imgs-lazyload {
            display: block;
        }
    }
    .noStock {
        .noStock-title {
            margin-bottom: 20px;
        }
    }
}
.goods-bottom .el-tabs .el-image {
    vertical-align: top;
}

@import "@/assets/scss/var.scss";
@media (max-width: $tablet-max) {

    .goods {
        &-content {
            flex-direction: column;
            
        }
        .goods-number {
            margin-bottom: $offset-v;
            display: block;
        }
        .btnGrp-container.is-active .goods-number {
            margin-bottom: 0;
        }

        // 商品页tab置顶时样式处理
        .goods-content-left {
            width: 100%;
            margin-right: 0;
        }
        .goods-content-right .btnGrp-container.is-active {
            top: auto;
            bottom: 0;
            width: 100% !important; 
            left: 0 !important;
            transform: none;
            background: #fff;
            margin-bottom: 0;
        }
        .goods-bottom .el-tabs.is-active .el-tabs__header {
            height: auto;
        }
        .goods-bottom .el-tabs.is-active .el-tabs__header .el-tabs__nav-wrap {
            line-height: 40px;
        }


    }
}

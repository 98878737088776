@import "@/assets/scss/var.scss";
.sw-card{
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
    &-item{
        margin: $offset-base;
        cursor: pointer;
        width: calc(25% - 10px - 10px);
        margin: $offset-base-lg $offset-base 0 $offset-base;
        // float: left;
        box-sizing: border-box;
        .el-card {
            box-shadow: none;
            height: 100%;
        }
        &__img{
            height: 232px;
        }
        &__info{
            padding: $offset-base-md;
            height: calc(100% - 232px);
            box-sizing: border-box;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            &-title{
                // height: 44px;
            }
            &-desc{
                color:$txt-color-grey;
                font-size: $fontsize-s;
                @extend .ellipsis
            }
            >div{
                margin-top: $offset-v;
            }
            >div:first-child{
                margin-top: 0;
            }
        }
        &__bottom{
            display: flex;
            align-items: center;
            &-oprice{
                font-size: $fontsize-sm;
                color: #999;
                text-decoration: line-through;
                margin-right: $offset-h;
            }
            &-price{
                font-size: $fontsize;
                color: red;
                flex: 1;
            }
            &-button{
                padding: 0;
                float: right;
            }
        }
        /deep/ .el-card__body{
            padding: 0;
            height: 100%;
        }
    }
}

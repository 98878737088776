
$grey-color: #333;
$right:right;
$left:left;

$fontcolor:#000;


//公共宽度
$com-width:1000px;
$com-min-width:960px;
$com-offset: 15px;

// 行为颜色
$color-primary: #ef0f25;
$color-success: #4cd964;
$color-warning: #f0ad4e;
$color-error: #ef0f25;

$color-shop: $color-primary;
$color-logo: #06be88;

$iconColor: $color-primary;

//按钮颜色
$btn-color-main:linear-gradient(90deg,#ff6034,#ee0a24);
$btn-color-fb:#3487fd;
$btn-color-primary:$color-primary;

// 文字颜色
$txt-color: #343635; //基本色
$txt-color-primary: $color-primary;
$txt-color-grey: #9a9e9f; //辅助灰色，如加载更多的提示讯息
$txt-color-placeholder: #808080;
$txt-color-disable: #c1c5c6;

// 背景颜色
$bg-color-grey: #f8f8f8;
$bg-color-mask: rgba(0, 0, 0, 0.5);
$bg-color-disabled: #c1c5c6;

// 边框颜色
$border: #ebedf0; // 与vantui保持一致
$border-primary: $color-primary;

// 字体大小
$fontsize: 16px;
$fontsize-s: 12px;
$fontsize-sm: 14px;
$fontsize-lg: 18px;

// 圆角
$radius-sm: 2px;
$radius: 5px;
$radius-lg: 12px;
$radius-circle: 999px;

//base size
$offset-base: 10px;
$offset-base-sm: $offset-base / 2;
$offset-base-md: ($offset-base / 2)*3;
$offset-base-lg: $offset-base * 2;

// 水平间距
$offset-h-sm: $offset-base / 2;
$offset-h: $offset-base;
$offset-h-md: ($offset-base / 2)*3;
$offset-h-lg: $offset-base * 2;

// 垂直间距
$offset-v-sm: $offset-base / 2;
$offset-v: $offset-base;
$offset-v-md: ($offset-base / 2)*3;
$offset-v-lg: $offset-base * 2;

$offset-cell: 16px;
$offset-cell-v: $offset-cell * 0.625;

// device size
$desktop: 1024px;
$tablet: 768px;
$tablet-max: $tablet - 1px;
$mobile: 414px;

.close {
    position: relative;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;

    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 50%;
        border-top: 1px solid;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.twoRows{
    white-space: normal;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.media-play {
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        position: absolute;
        left: $offset-h + 9px;
        top: 50%;
        margin-top: -1px;
        transform: translate(0, -50%);
        border-style: solid;
        border-width: 5px 7px;
        border-color: transparent transparent transparent #fff;
    }
}



@mixin thumb-square($percent: 100%) {
  padding-bottom: $percent;
  position: relative;
  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

:export {
    tabletSize: $tablet;
    mobileSize: $mobile
}
